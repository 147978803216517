<template>
  <div
    class="rounded-[8px] !p-5 bg-ems-tag1 flex !gap-5 w-[400px] custom-skeleton"
  >
    <a-skeleton-image />
    <a-skeleton :loading="true" :paragraph="{ rows: 1 }" active />
  </div>
</template>
<script setup></script>
<style lang="scss">
@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.custom-skeleton .ant-skeleton-image {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 123, 123, var(--tw-bg-opacity));
  border-radius: 8px;
}
.custom-skeleton .ant-skeleton-title, .custom-skeleton .ant-skeleton-paragraph li {
  background-image: linear-gradient(
        90deg,
        #C4C4C4 25%,
        #9D9D9D 37%,
        #D9D9D9 63%
      ) !important;
}
</style>
