<template>
  <div class="create-io">
    <a-input
      size="large"
      :placeholder="t('group-management.placeholder-input-search')"
      v-model:value="formState.name"
      class="w-100 ant-input-sm rounded-[8px] bg-ems-tag1 border-none !mb-[18px]"
      @pressEnter="searchIO"
      :allow-clear="true"
      @change="delaySearch"
    >
      <template #prefix>
        <img
          src="@/static/img/group-management/input-search.svg"
          alt="input-suffix"
          height="16"
          width="16"
        />
      </template>
    </a-input>
    <cds-select
      type="text"
      v-model:value="formState.type"
      :options="IO_TYPE"
      class="ant-input-sm mb-[18px]"
      :placeholder="t('group-management.type-placeholder')"
      @select="searchIO"
    />
    <span class="text-base text-ems-gray200 font-semibold">{{
      t('group-management.selected')
    }}</span>
    <div class="py-[4px] px-[10px] bg-ems-tag1 rounded-[8px] mt-[8px] h-[38px]">
      <span
        v-if="Object.keys(listSelectedIO).length === 0"
        class="text-base text-ems-gray500 leading-[32px]"
        >{{ t('group-management.selected-io') }}</span
      >

      <div v-else class="overflow-x-auto">
        <div class="h-[32px] pb-1 flex w-[max-content]">
          <span
            class="flex items-center bg-ems-gray600 border-none py-1 pl-1 pr-2 rounded-[20px] overflow-hidden group relative mr-1"
            v-for="(item, idx) in listSelectedIO"
            :key="item.id"
          >
            <img
              :src="
                !item.imagesAvatar
                  ? require('@/static/img/no-img.png')
                  : item.imagesAvatar
              "
              :alt="item.imagesAvatar"
              width="20"
              height="20"
              class="rounded-full border-1 border-solid border-ems-gray300 mr-1 w-[20px] h-[20px]"
            />
            <span
              class="text-white text-[12px] font-normal whitespace-nowrap"
              >{{ item.name }}</span
            >
            <cds-feather-icons
              type="x"
              size="12"
              class="text-ems-gray600 rounded-full p-[4px] absolute top-[3px] left-1 hidden m-0 bg-white group-hover:block cursor-pointer"
              @click="removeIO(item)"
            />
          </span>
        </div>
      </div>
    </div>
    <span
      v-if="emptySelectedIO"
      class="text-[12px] text-ems-main2 block mt-2"
      >{{ t('group-management.empty-selected-io') }}</span
    >
    <div class="h-[1px] w-full bg-ems-gray600 !mt-3 !mb-3"></div>
    <span class="inline-block mb-[15px] font-semibold">{{
      t('group-management.suggest')
    }}</span>
    <CustomSkeleton
      v-if="listObjects.length === 0 && isLoading"
      class="!w-[auto] mb-4"
    />
    <Empty
      v-else-if="listObjects.length === 0"
      :description="t('group-management.list-object-empty')"
      class="my-10"
    />
    <div
      v-else
      class="h-[450px] overflow-y-auto pr-1 flex flex-col justify-between gap-3"
    >
      <SuggestObject
        v-for="(item, idx) in listObjects"
        :key="item.id"
        :item="item"
        :searchValue="formState.name"
        @selected-io="onSelectedIO"
        :listGroups="listGroups"
      />
      <CustomSkeleton v-if="isLoading" class="!w-[auto]" />
      <div
        class="w-full flex justify-center"
        v-if="listObjects.length > 0"
      >
        <a-button
          type="primary"
          class="!text-ems-main2 !border-ems-main2 text-base font-semibold"
          ghost
          @click="viewMore"
          v-if="totalIO > 10 * (page + 1)"
          >{{ t('common.view-more') }}</a-button
        >
      </div>
    </div>

    <div class="flex justify-center absolute mx-auto bottom-[-50px] w-full pr-[85px]">
      <div v-if="isLoadingAddIO" class="spin bg-transparent h-[20px]">
        <a-spin />
      </div>
      <ClipButton
        v-else
        :type="'primary'"
        class="!px-10"
        @click="onAddIO"
        :disabled="listObjects.length === 0"
      >
        <span>{{ t('group-management.add') }}</span>
      </ClipButton>
    </div>
  </div>
</template>
<script setup>
import { i18n } from '@/main';
import { computed, onMounted, ref, reactive, defineEmits, inject } from 'vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useStore } from 'vuex';
import { IO_TYPE } from '@/config/Constant.js';
import CustomSkeleton from './CustomSkeleton.vue';
import SuggestObject from './SuggestObject.vue';
import _ from 'lodash';
import { debounce } from 'lodash';
import Empty from '@/components/empty/index.vue';

const eventBus = inject('eventBus');
const { t } = i18n.global;
const { state, dispatch } = useStore();
const page = ref(0);
const totalIO = computed(() => state.ioGroup.totalIONotInGroup);
const listGroups = computed(() => {
  let groups = {};
  state.ioGroup.listGroup.map((el) => {
    groups[el.id] = el;
  });
  return groups;
});
const DEBOUNCE_TIME = 300;
const idGroup = computed(() => state.ioGroup.selectedGroup.id);
const delaySearch = debounce(() => {
  searchIO();
}, DEBOUNCE_TIME);
onMounted(async () => {
  isLoading.value = true;
  await dispatch('ioGroup/getListIONotInGroup', {
    payload: { ...formState, size: 10, page: page.value, id: idGroup.value },
    isViewMore: false,
  });
  isLoading.value = false;
});
const isLoading = ref(false);
const searchIO = () => {
  page.value = 0;
  let data = { ...formState, size: 10, page: page.value, id: idGroup.value };
  if (formState.type === '') {
    data.type = null;
  }
  dispatch('ioGroup/getListIONotInGroup', {
    payload: data,
    isViewMore: false,
  });
};
const viewMore = async () => {
  isLoading.value = true;
  page.value++;
  await dispatch('ioGroup/getListIONotInGroup', {
    payload: { ...formState, size: 10, page: page.value, id: idGroup.value },
    isViewMore: true,
  });
  isLoading.value = false;
};
const listObjects = computed(() => {
  let list = _.cloneDeep(state.ioGroup.listIONotInGroup);
  list.map((e) => {
    if (listSelectedIO.value[e.id]) {
      e.checked = true;
    }
  });
  return list;
});
const listSelectedIO = ref({});
let formState = reactive({
  name: '',
  type: null,
});

const onSelectedIO = (io) => {
  if (io.checked) {
    listSelectedIO.value[io.id] = io;
  } else {
    removeIO(io);
  }
};

const removeIO = (io) => {
  delete listSelectedIO.value[io.id];
};

const emptySelectedIO = ref(false);
const isLoadingAddIO = ref(false);
const emit = defineEmits(['onSearchAdvanced']);
const onAddIO = async () => {
  isLoadingAddIO.value = true;
  if (Object.keys(listSelectedIO.value).length === 0) {
    emptySelectedIO.value = true;
    isLoadingAddIO.value = false;
  } else {
    let res = await dispatch('ioGroup/addIOToGroup', listSelectedIO.value);
    isLoadingAddIO.value = false;
    if (res) {
      dispatch('ioGroup/getListIO', {
        groupId: idGroup.value,
        page: 0,
        size: 6,
      });
      resetData();
      eventBus.emit('list-io-group-refresh-filter');
      emit('onSearchAdvanced');
    }
  }
};
const resetData = () => {
  formState = reactive({
    name: '',
    type: null,
  });
  listSelectedIO.value = {};
  dispatch('ioGroup/getListIONotInGroup', {
    payload: {
      ...formState,
      size: 10,
      page: page.value,
      id: idGroup.value,
    },
    isViewMore: false,
  });
};
</script>

<style lang="scss">
.create-io .ant-select {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  border-radius: 8px;
  height: 38px;
  width: 100%;
}
.create-io .ant-select-selector {
  background-color: #373737 !important;
  border: none !important;
  border-radius: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.create-io .ant-select-selector input {
  height: 24px !important;
}
.create-io .ant-select-selector :hover {
  border: none !important;
}
.create-io .ant-select-selector .ant-select-selection-search {
  background-color: #373737 !important;
}
.create-io .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.create-io .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
</style>
